<template>
  <div class="dump-page animated fadeIn container">
    <h1>数据库下載</h1>
    <div class="mt-4" style="display: flex; align-items: center">
      <select name="selectType" class="select" v-model="selectType">
        <option value="date">Date</option>
        <option value="requestId">Request ID</option>
      </select>
      <div v-show="selectType == 'requestId'">
        <input type="text" class="input ml-2" placeholder="1234 or 1111,2222" v-model="targetRequestIds" />
      </div>

      <div v-show="selectType == 'date'">
        <datetime
          class="float-left ml-2"
          type="date"
          v-model="startDate"
          zone="Asia/Shanghai"
          :phrases="{ ok: 'Ok', cancel: 'Cancel' }"
          placeholder="開始日期"
        />
        <datetime
          class="float-left ml-2"
          type="date"
          v-model="endDate"
          zone="Asia/Shanghai"
          :phrases="{ ok: 'Ok', cancel: 'Cancel' }"
          placeholder="結束日期"
        />
      </div>
      <div class="float-left ml-2 custom-control custom-checkbox custom-control-inline">
        <input type="checkbox" class="custom-control-input" id="needSuccessCheck" v-model="needSuccess" />
        <label class="custom-control-label" for="needSuccessCheck">成功</label>
      </div>
      <div class="float-left ml-2 custom-control custom-checkbox custom-control-inline">
        <input type="checkbox" class="custom-control-input" id="needWaitCheck" v-model="needWait" />
        <label class="custom-control-label" for="needWaitCheck">等待</label>
      </div>
      <div class="float-left ml-2 custom-control custom-checkbox custom-control-inline">
        <input type="checkbox" class="custom-control-input" id="needFailCheck" v-model="needFail" />
        <label class="custom-control-label" for="needFailCheck">失败</label>
      </div>
      <div style="flex-grow: 1"></div>
      <b-btn variant="primary" class="float-left ml-1" @click.prevent="create">要求</b-btn>
    </div>
    <table class="border-table mt-4" v-if="items.length > 0">
      <thead>
        <tr>
          <td width="200px">查詢日期</td>
          <td>請求信息</td>
          <td width="250px"></td>
          <td class="text-center">地位</td>
        </tr>
      </thead>
      <tbody>
        <tr :key="item.id" v-for="item in items">
          <td>
            {{ item.createdAt | dateFormat }}
          </td>
          <td>
            {{ getStatusListText(item.statusList) }}
          </td>
          <td v-if="item.targetIds != null">
            {{ item.targetIds }}
          </td>
          <td v-else>{{ item.startDate | dateShortFormat }} ~ {{ item.endDate | dateShortFormat }}</td>
          <td class="text-center">
            <button
              class="btn btn-info"
              v-on:click="getFile(item.filePath)"
              v-show="isRecent(item.createdAt) && item.filePath != null"
              style="color: white"
            >
              下載
            </button>
            <span v-show="item.filePath == null" style="color: #63c2de">準備好</span>
            <span v-show="!isRecent(item.createdAt)" style="color: #9a9a9a">過期</span>
          </td>
        </tr>
      </tbody>
    </table>
    <b-pagination
      align="center"
      :total-rows="totalCount"
      v-model="currentPage"
      :per-page="10"
      :limit="20"
      class="mt-4"
      @change="changePage"
      v-show="totalCount > limit"
    ></b-pagination>
  </div>
</template>

<script>
import DumpService from '@/services/DumpService'
import { Datetime } from 'vue-datetime'
import moment from 'moment'
import axios from 'axios'

export default {
  name: 'Dumps',
  components: {
    Datetime,
  },
  data() {
    return {
      items: [],
      selectType: 'date',
      startDate: '',
      endDate: '',
      needSuccess: true,
      needFail: true,
      needWait: true,
      totalCount: 0,
      currentPage: null,
      targetRequestIds: '',
    }
  },
  mounted() {
    document.getElementsByClassName('app-body')[0].classList.add('bg-white')

    if (this.$route.query.requestId) {
      this.selectType = 'requestId'
      this.targetRequestIds = this.$route.query.requestId
    }

    const type = this.$route.query.type
    if (this.$route.query.type == 'total') {
      this.needSuccess = true
      this.needFail = true
      this.needWait = true
    } else if (this.$route.query.type == 'success') {
      this.needSuccess = true
      this.needWait = false
      this.needFail = false
    } else if (this.$route.query.type == 'fail') {
      this.needSuccess = false
      this.needWait = false
      this.needFail = true
    } else if (this.$route.query.type == 'wait') {
      this.needSuccess = false
      this.needWait = true
      this.needFail = false
    }

    this.getList()
  },
  methods: {
    async getFile(filePath) {
      axios({
        url: '/api/file?fileKey=' + filePath,
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filePath.split('/').pop())
        document.body.appendChild(link)
        link.click()
      })
    },
    async getList() {
      const response = await DumpService.list({
        page: this.currentPage,
      })
      if (response.status !== 200) {
        return
      }

      const { list, totalCount } = response.data
      this.items = list
      this.totalCount = totalCount
    },
    async create() {
      let params = {
        needSuccess: this.needSuccess,
        needWait: this.needWait,
        needFail: this.needFail,
      }

      if (this.selectType == 'date') {
        if (this.startDate.length == 0) {
          alert('請輸入開始日期')
          return
        }
        if (this.endDate.length == 0) {
          alert('請輸入結束日期')
          return
        }

        params['startDate'] = this.startDate.substring(0, 10)
        params['endDate'] = this.endDate.substring(0, 10)
      } else {
        if (this.targetRequestIds.length == 0) {
          alert('Request ID is required')
          return
        }

        params['targetIds'] = this.targetRequestIds
      }

      const response = await DumpService.create(params)
      if (response.status == 200) {
        alert('成功')
        this.startDate = ''
        this.endDate
        this.needSuccess = true
        this.needFail = true
        this.targetRequestIds = ''

        this.getList()
      }
    },
    getStatusListText(statusList) {
      statusList = statusList.replace('0', '等待')
      statusList = statusList.replace('2', '成功')
      statusList = statusList.replace('3', '失败')
      return statusList
    },
    isRecent(createdAt) {
      if (!createdAt || createdAt.length == 0) {
        return
      }

      var date = moment().add(-3, 'days')
      return date.isBefore(moment(String(createdAt)))
    },
    changePage(page) {
      var query = {
        page: page,
      }

      this.$router.push({
        path: '/dumps',
        query,
      })
    },
  },
  watch: {
    '$route.query.page'() {
      this.currentPage = this.$route.query.page
      this.getList()
    },
    '$route.query.type'() {
      //
    },
    selectType() {
      //
    },
  },
}
</script>
<style>
.select,
.input,
.vdatetime-input {
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  font-weight: 400;
  border: 1px solid #e4e7ea;
  color: #5c6873;
}

.vdatetime-input {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  border: 1px solid #e4e7ea;
  height: 100%;
}

.single-line {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}
</style>
